import { axiosBasicPost, axiosGet, axiosPost, axiosRefreshGet } from "../axios"
import {
  PASSWORD_UPDATE_FAIL,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  VALIDATE_LOGIN_DETAILS_SUCCESS,
  VALIDATE_LOGIN_DETAILS_REQUEST,
  VALIDATE_LOGIN_DETAILS_FAIL,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  FORGET_PASSWORD_OPT_REQUEST,
  FORGET_PASSWORD_OPT_SUCCESS,
  FORGET_PASSWORD_OPT_FAIL,
  UPDATE_PROFILE_DETAILS_REQUEST,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS_FAIL,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_FAIL,
  POST_FEEDBACK_REQUEST,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAIL,
  POST_FEEDBACK_RESET
} from "../types/userConstant"
import AsyncLocalStorage from "@createnextapp/async-local-storage"
import { loginTypeChecker } from "../../utils/loinTypeChecker"
import { deviceInfo } from "../../utils/fixedInfo"
import { Mixpanel } from "../../utils/mixpanel"
import moment from "moment"

let previousUrlWithoutAuth

const isEnrollmentSpl = profileType => profileType?.toLowerCase() === "enrollment_specialist"

export const userLoginAction = (userName, password, extension) => async dispatch => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST
    })
    let type = loginTypeChecker(userName)
    let info = deviceInfo()
    const { data } = await axiosBasicPost(`/api/auth/authorize`, {
      userName,
      password,
      loginTypes: extension ? "landline" : type,
      extension: extension ? extension : null,
      appVersion: info.appVersion,
      deviceId: info.deviceId,
      deviceModel: info.deviceModel
    })

    if (!isEnrollmentSpl(data?.details?.userType)) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "Invalid Credentials"
      })
      return
    }

    localStorage.setItem("token", JSON.stringify(data?.details?.token))
    localStorage.setItem("refreshToken", JSON.stringify(data?.details?.refreshToken))
    localStorage.setItem("id", JSON.stringify(data?.details?.id))

    if (data?.details?.token && data?.details?.refreshToken && data?.details?.id) {
      await dispatch(
        getUserProfileAction({
          id: data?.details?.id,
          userType: data?.details?.userType,
          redirectToHome: true
        })
      )
    }
  } catch (error) {
    //error?.response?.status === 401 && dispatch(prevUserLogoutAction())
    const errDetails = error?.response?.data?.details
    if (typeof errDetails === "object") {
      const keys = Object.keys(errDetails)
      if (keys.includes("count") && keys.includes("blockedTill") && keys.includes("attemptsLeft")) {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: errDetails
        })
      } else {
        dispatch({
          type: USER_LOGIN_FAIL,
          payload: Object.values(errDetails)?.[0]
        })
      }
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: "Something went wrong, Please try again!"
      })
    }
  }
}

export const googleLoginAction =
  ({ credential }) =>
  async dispatch => {
    try {
      dispatch({
        type: GOOGLE_LOGIN_REQUEST
      })
      let info = deviceInfo()
      const { data } = await axiosBasicPost(`/api/auth/authorize`, {
        userName: credential,
        loginTypes: "google",
        appVersion: info.appVersion,
        deviceId: info.deviceId,
        deviceModel: info.deviceModel
      })
      if (!isEnrollmentSpl(data?.details?.userType)) {
        dispatch({
          type: GOOGLE_LOGIN_FAIL,
          payload: "Invalid Credentials"
        })
        return
      }
      localStorage.setItem("token", JSON.stringify(data?.details?.token))
      localStorage.setItem("refreshToken", JSON.stringify(data?.details?.refreshToken))
      localStorage.setItem("id", JSON.stringify(data?.details?.id))

      if (data?.details?.token && data?.details?.refreshToken && data?.details?.id) {
        await dispatch(
          getUserProfileAction({
            id: data?.details?.id,
            userType: data?.details?.userType,
            redirectToHome: true
          })
        )
      }
    } catch (error) {
      dispatch({
        type: GOOGLE_LOGIN_FAIL,
        payload:
          typeof error?.response?.data?.details === "object" &&
          Object.values(error?.response?.data?.details)?.[0]
      })
    }
  }

export const clearUserLoginError = () => async dispatch => {
  dispatch({
    type: USER_LOGOUT
  })
}

export const validateLoginDetailsAction = (userName, prevUrl) => async dispatch => {
  previousUrlWithoutAuth = prevUrl
  let loginTypes = loginTypeChecker(userName)
  try {
    dispatch({
      type: VALIDATE_LOGIN_DETAILS_REQUEST
    })

    const { data } = await axiosBasicPost(`/api/auth/validate`, { userName, loginTypes })

    if (!isEnrollmentSpl(data?.details?.profileType)) {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: "Invalid Credentials"
      })
      return
    }

    dispatch({
      type: VALIDATE_LOGIN_DETAILS_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    // error?.response?.status === 401 && dispatch(userLogoutAction())
    if (
      loginTypes === "email" &&
      error.response &&
      error?.response?.data?.details?.message === "Invalid login details"
    ) {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: "Please verify your mail"
      })
    } else if (
      loginTypes === "mobileNumber" &&
      error.response &&
      error?.response?.data?.details?.message === "Invalid login details"
    ) {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: "Please verify your number"
      })
    } else {
      dispatch({
        type: VALIDATE_LOGIN_DETAILS_FAIL,
        payload: error.response && error?.response?.data?.details
      })
    }

    return error.response
  }
}

export const passwordUpdateAction = (userName, otp, password) => async dispatch => {
  let loginTypes = loginTypeChecker(userName)
  try {
    dispatch({
      type: PASSWORD_UPDATE_REQUEST
    })
    const { data } = await axiosBasicPost(`/api/auth/submitOTP`, {
      userName,
      loginTypes,
      otp,
      password
    })

    dispatch({
      type: PASSWORD_UPDATE_SUCCESS,
      payload: data
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    // error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PASSWORD_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error
  }
}

export const forgetPasswordAction = userName => async dispatch => {
  try {
    dispatch({
      type: FORGET_PASSWORD_OPT_REQUEST
    })

    let loginTypes = loginTypeChecker(userName)
    let types = deviceInfo()
    const { data } = await axiosBasicPost(`/api/auth/forgotPassword`, {
      userName,
      loginTypes,
      deviceId: types.deviceId,
      deviceModel: types.deviceModel,
      appVersion: types.appVersion
    })

    dispatch({
      type: FORGET_PASSWORD_OPT_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    dispatch({
      type: FORGET_PASSWORD_OPT_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error.response && error?.response?.data?.details
  }
}

export const validateOtpAction = (userName, otp) => async dispatch => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST
    })

    let loginTypes = loginTypeChecker(userName)
    const { data } = await axiosBasicPost(`/api/auth/validateOTP`, {
      userName,
      loginTypes,
      otp
    })

    return data
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error.response && error?.response?.data?.details
  }
}

// // Password reset from dashboard->security
export const passwordResetAction = (password, oldPassword) => async dispatch => {
  let info = deviceInfo()
  try {
    dispatch({
      type: PASSWORD_UPDATE_REQUEST
    })
    const { data } = await axiosPost(`/api/resetPassword`, {
      password,
      oldPassword,
      appVersion: info.appVersion,
      deviceId: info.deviceId,
      deviceModel: info.deviceModel
    })

    dispatch({
      type: PASSWORD_UPDATE_SUCCESS,
      payload: data
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: PASSWORD_UPDATE_FAIL,
      payload: error.response && error?.response?.data?.details?.password
    })
    return error
  }
}

export const getUserProfileAction =
  ({ id, userType, redirectToHome }) =>
  async dispatch => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
      }
    }
    //different api will be used
    Mixpanel.identify(id)
    const { data } = await axiosGet(`/api/cs/caretaker/${id}/fetch`, config)

    await AsyncLocalStorage.setItem("userInfo", JSON.stringify(data?.details))
    // localStorage.setItem("profileImage", JSON.stringify(data.profileImage));

    Mixpanel.people.set({
      ID: id,
      Type: userType,
      UserName: data?.details?.userName,
      $name: `${data?.details?.firstName} ${data?.details?.middleName || ""} ${data?.details?.lastName}`,
      $email: data?.details?.email,
      orgID: data?.details?.orgId
    })
    Mixpanel.register({
      ID: id,
      Type: userType,
      UserName: data?.details?.userName,
      Email: data?.details?.email,
      orgID: data?.details?.orgId
    })
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data?.details
    })
    // dispatch({ type: GET_ADMIN_SUCCESS, payload: data.profileImage });
    if (redirectToHome && data?.details?.id !== null) {
      document.location.href = previousUrlWithoutAuth ? previousUrlWithoutAuth : "/"
    }
    return data
  }

// const handleLogout = async () => {
//   try {
//     await axiosGet("/support/v1/logout")
//   } catch (error) {
//     document.location.href = "/login"
//   }
// }

export const prevUserLogoutAction = () => async dispatch => {
  const res = await axiosGet(`/api/cs/config/logout`)
  if (res?.data?.message === "Success") {
    // window.webkit.messageHandlers.logout.postMessage({ value: "harish" });
    localStorage.clear()
    dispatch({ type: USER_LOGOUT })
    document.location.href = "/login"
  }
}

export const userLogoutAction = () => async dispatch => {
  try {
    const res = await axiosRefreshGet("/api/refresh")
    if (res?.data?.details?.token && res?.data?.details?.refreshToken && res?.data?.details?.id) {
      await AsyncLocalStorage.setItem("token", JSON.stringify(res?.data?.details?.token))
        .then(() => {
          AsyncLocalStorage.setItem(
            "refreshToken",
            JSON.stringify(res?.data?.details?.refreshToken)
          )
        })
        .then(() => {
          AsyncLocalStorage.setItem("id", JSON.stringify(res?.data?.details?.id))
        })
        .then(() => {
          window.location.reload()
        })
    }
  } catch (err) {
    localStorage.clear()
    //await handleLogout()
    dispatch({ type: USER_LOGOUT })
    document.location.href = "/login"
  }
}

export const updateProfileDetailsAction = (caretakerId, formData) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_PROFILE_DETAILS_REQUEST
    })

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
        "Content-Type": "application/json"
      }
    }

    const { data } = await axiosPost(`/api/cs/caretaker/${caretakerId}/update`, formData, config)

    dispatch({
      type: UPDATE_PROFILE_DETAILS_SUCCESS,
      payload: data.details
    })

    return data

    // dispatch(userLogoutAction())
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: UPDATE_PROFILE_DETAILS_FAIL,
      payload: error.response && error?.response?.data?.details
    })
    return error
  }
}

// Submit Feedback: Dashboard -> Settings -> Feedback
export const postFeedbackAction =
  ({ userId, feedBackType, feedBack }) =>
  async dispatch => {
    try {
      dispatch({
        type: POST_FEEDBACK_REQUEST
      })
      await axiosPost(`/api/cs/config/feedback/${userId}/create`, {
        feedBackType,
        feedBack,
        profileType: "enrollment_specialist",
        userDate: moment().format("yyyy-MM-DDTHH:mm:ss.SSS")
      })

      const status = { isSuccess: true }

      dispatch({
        type: POST_FEEDBACK_SUCCESS,
        payload: status
      })

      return status

      // dispatch(userLogoutAction())
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: POST_FEEDBACK_FAIL,
        payload: error?.response?.data?.details && Object.values(error?.response?.data?.details)
      })
      return error
    }
  }

export const resetPostFeedbackErrAction = () => async dispatch => {
  dispatch({
    type: POST_FEEDBACK_RESET
  })
}
