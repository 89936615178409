import React, { useState, useEffect } from "react"

import { Layout, Grid, ConfigProvider } from "antd"

import { useToasts } from "react-toast-notifications"
import localeLang from "antd/es/locale/en_US"
// import { useSelector } from "react-redux"
import SidebarOrg from "./SidebarOrg"

import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import NoNetworkHandler from "../utils/NoNetworkHandler"

import "./main-layout.css"

const { useBreakpoint } = Grid
const { Content, Footer } = Layout

const MainLayout = props => {
  const [sidebarHidden, setSidebarHidden] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

  const screens = useBreakpoint()
  const { addToast } = useToasts()

  useEffect(() => {
    if (screens.lg === false) {
      setSidebarHidden(true)
      setSidebarCollapsed(false)
    } else {
      setSidebarHidden(false)
    }
  }, [screens.lg])

  useEffect(() => {
    const { message, appearance, autoDismiss } = NoNetworkHandler()
    if (message) {
      addToast(message, { appearance, autoDismiss })
    }
  }, []) // eslint-disable-line

  return (
    <ConfigProvider
      locale={localeLang}
      theme={{
        token: {
          colorPrimary: "#007aff"
        }
      }}>
      <Layout className="main-layout">
        {props.from === "org" ? (
          <SidebarOrg
            style={
              screens.lg
                ? {
                    overflow: "auto",
                    height: "100vh",
                    position: "sticky",
                    left: 0,
                    top: 0,
                    bottom: 0
                  }
                : {}
            }
            sidebarHidden={sidebarHidden}
            sidebarCollapsed={sidebarCollapsed}
            setSidebarHidden={setSidebarHidden}
            setSidebarCollapsed={setSidebarCollapsed}
          />
        ) : (
          <Sidebar
            sidebarHidden={sidebarHidden}
            sidebarCollapsed={sidebarCollapsed}
            setSidebarHidden={setSidebarHidden}
            setSidebarCollapsed={setSidebarCollapsed}
          />
        )}
        <Layout>
          <Navbar
            sidebarHidden={sidebarHidden}
            setSidebarHidden={setSidebarHidden}
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
            from={props.from}
          />
          <Content>{props.children}</Content>
          <Footer style={{ textAlign: "center" }}>
            <h6> &#169; {new Date().getFullYear()}, FitPeo inc. All Rights Reserved</h6>
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

export default MainLayout
