import {
  PASSWORD_UPDATE_FAIL,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  VALIDATE_LOGIN_DETAILS_FAIL,
  VALIDATE_LOGIN_DETAILS_REQUEST,
  VALIDATE_LOGIN_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGIN_RESET,
  UPDATE_PROFILE_DETAILS_REQUEST,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS_FAIL,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAIL,
  GOOGLE_LOGIN_RESET,
  POST_FEEDBACK_REQUEST,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAIL,
  POST_FEEDBACK_RESET
} from "../types/userConstant"

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null

const initialState = {
  userInfo: { loading: false, userInfo: userInfoFromStorage }
}

export const googleLoginReducer = (state = initialState.userInfo, action) => {
  switch (action.type) {
    case GOOGLE_LOGIN_REQUEST:
      return { loading: true }
    case GOOGLE_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case GOOGLE_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case GOOGLE_LOGIN_RESET:
      return { loading: false }
    default:
      return state
  }
}

export const userLoginReducer = (state = initialState.userInfo, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGIN_RESET:
      return { loading: false }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const validateLoginDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case VALIDATE_LOGIN_DETAILS_REQUEST:
      return { loading: true }
    case VALIDATE_LOGIN_DETAILS_SUCCESS:
      return { loading: false, data: action.payload }
    case VALIDATE_LOGIN_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const passwordUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PASSWORD_UPDATE_REQUEST:
      return { loading: true }
    case PASSWORD_UPDATE_SUCCESS:
      return { loading: false, passwordUpdate: action.payload }
    case PASSWORD_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateProfileDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_DETAILS_REQUEST:
      return { loading: true }
    case UPDATE_PROFILE_DETAILS_SUCCESS:
      return { loading: false, updateProfileDetails: action.payload }
    case UPDATE_PROFILE_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postFeedbackReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_FEEDBACK_REQUEST:
      return { loading: true }
    case POST_FEEDBACK_SUCCESS:
      return { loading: false, status: action.payload }
    case POST_FEEDBACK_FAIL:
      return { loading: false, error: action.payload }
    case POST_FEEDBACK_RESET:
      return {}
    default:
      return state
  }
}
